
body {
  background-color: #080808;
}

.main_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#header_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.from_logo {

}

.to_logo {

}

.iframe_container {
  height: 100%;
}